import { fetchBase64 } from 'core'
import FONT_URL from '@assets/fonts/Inter/Inter-Variable.woff2?url'

/** Loads fonts as base64 given css url */
async function getFontsAsBase64(cssURL: string) {
  let cssText = await fetch(cssURL).then(e => e.text())

  const fontURLs = cssText.match(/https:\/\/[^)]+/g)

  if (!fontURLs || cssText.includes('Font family not found'))
    throw new Error('Could not find any fonts in the stylesheet')

  // Load base64 fonts
  const fonts = await Promise.all(
    fontURLs.map(async (url) => {
      const result = await fetchBase64(url)
      return [url, result]
    })
  )

  // Replace urls with base64 fonts
  fonts.forEach(([url, data]) => {
    cssText = cssText.replace(url, data)
  })

  return cssText
}

/** Loads fonts as base64 from Google Fonts */
export async function getGoogleFont(name: string, weights = [400, 500]) {
  return getFontsAsBase64(
    `https://fonts.googleapis.com/icon?family=${encodeURIComponent(name)}:wght@${weights.join(';')}&display=swap`
  )
}

/** Loads Inter font */
async function getFallbackFont() {
  const fontData = await fetchBase64(FONT_URL)

  return `
    @font-face { 
      font-family: "Inter";
      font-style: normal;
      font-weight: 100 900;
      font-display: swap;
      src: url(${fontData}) format('woff2');
    }
  `
}

/** Loads font for signature with fallback support */
export function getFont(...args: Parameters<typeof getGoogleFont>) {
  return getGoogleFont(...args)
    .catch(getFallbackFont)
    .catch((error) => {
      ErrorService.log(error, { title: 'Failed to load any fonts' })
      return ''
    })
}
