import { defineStore } from 'pinia'
import { Plan, Plans, animateDOM, useUserAccount } from 'core'
import type { ReactiveStripeElement } from './stripe.element'
import { useEvents } from './config'

export enum State {
  checkout = 'checkout',
  success = 'success',
  error = 'error'
}

export const useState = defineStore('paywall', {
  state: () => ({
    current: State.checkout,
    package: Plans.PRO.packages.yearly
  }),

  getters: {
    /** Annual packages are trial based */
    isTrialPackage: state => state.package.period === Plan.Period.Yearly,

    isSubscribed: () => useUserAccount().subscribed,
    currentPlan: () => useUserAccount().plan
  },

  actions: {
    setPackage(entry: Plan.Package) {
      this.package = entry
    },

    /**
     * This flow happens when server returns `checkout_url` (for 3ds or failed cases).
     * This method check if is subscribed after following the checkout_url
     * And then sets success state
     */
    async verify() {
      const user = useUserAccount()
      await user.sync.refresh()

      if (!user.subscribed)
        return

      this.current = State.success
    },

    subscribe(session: ReactiveStripeElement) {
      return ErrorService.try(
        async () => {
          const { paymentMethod: payment, error } = await session.getResult()
          const user = useUserAccount()
          const events = useEvents()

          // Invalid format failures
          if (error && error?.message) {
            session.state.error = error.message
            return false
          }

          if (!payment || !payment.id)
            throw new Error('Stripe returned nullish result')

          const { success, ...result } = await api.account.subscribe({
            priceId: this.package.key,
            paymentMethodId: payment.id,
            trial: this.isTrialPackage
          })

          Logger.debug('💳 Received result from payment', result)

          // Requires verification
          // Test with code: 4000000000000002
          if (result.checkout_url) {
            session.state.checkoutUrl = result.checkout_url
            session.state.error = result.message

            return false
          }

          if (!success) {
            session.state.error = result.message || 'Failed to process the payment. Please verify your credentials.'
          }

          // Update store
          else {
            await user.sync.refresh()
            events.success.trigger()
          }

          animateDOM(() => {
            user.subscribed = success
            this.current = success ? State.success : State.error
          })

          return success
        },

        // Error context
        {
          title: 'Failed while trying to subscribe',
          contexts: {
            'Subscription state': { ...this.$state }
          }
        }
      )
    },

    async unsubscribe() {
      const user = useUserAccount()
      const success = await api.account.unsubscribe()

      if (!success)
        return console.warn('unsubscribe failed')

      await user.sync.refresh()
      this.$reset()
    }
  }
})
