import type { API } from 'core'
import { DeferredPromise, getURLSearchParams, uuid } from 'core'
import { providers } from './providers'

export type AuthProvider = Exclude<API.Auth.Provider, 'email'>
export type AuthToken = string

const WEBAPP_ORIGIN = import.meta.env.VITE_APP_WEBAPP_ORIGIN
const WEBAPP_ORIGIN_LIST = [WEBAPP_ORIGIN, location.origin]

/** Broadcast channel fallback for window messaging */
const AuthChannel = 'BroadcastChannel' in globalThis
  ? new BroadcastChannel('vocal-auth-channel')
  : globalThis

class AuthSessionStorage {
  STORAGE_KEYS = ['provider', 'secret', 'origin'] as const

  get keys() {
    return this.STORAGE_KEYS.map(key => [key, `vocal-auth-key-${key}`] as const)
  }

  saveFromURL() {
    const params = getURLSearchParams()

    if (!params.provider)
      return

    // Store values to session storage
    this.keys.forEach(([key, storageKey]) => {
      const value = params[key]

      if (!value)
        Logger.warn(`URL does not contain proper values for authorization. ${key} is missing`)
      sessionStorage.setItem(storageKey, value)
    })
  }

  get data() {
    const record = {}! as Record<typeof this.STORAGE_KEYS[number], string>

    this.keys.forEach(([key, storageKey]) => {
      const value = sessionStorage.getItem(storageKey)

      if (!value)
        return Logger.warn(`SessionStorage does not contain proper values for authorization. ${key} (${storageKey}) is missing`)

      record[key] = decodeURIComponent(value)
    })

    return record
  }
}

/**
 * Used by authorization server.
 * Redirects to provider auth page and sends token back to client `AuthSession`
 */
export class AuthSessionHandler {
  #store = new AuthSessionStorage()

  constructor() {
    this.#store.saveFromURL()
  }

  async authorize() {
    const { provider } = this.#store.data

    // Exit if requested provider does not exist
    if (!provider || provider in providers === false)
      return

    const service = providers[provider as AuthProvider]

    // ? If hash is available, try to parse a usable token
    if (location.hash !== '') {
      const token = await service.parseToken()

      if (token)
        this.resolve(token)
      return token
    }

    // Otherwise redirect to provider auth page
    return service.login()
  }

  private resolve(token: AuthToken) {
    const { secret, origin } = this.#store.data
    const target: Window = window.opener || AuthChannel

    target.postMessage({ token, secret }, origin)
  }
}

/** Used by authorization client */
export class AuthSession {
  #secret = uuid()
  promise = new DeferredPromise<AuthToken>()

  constructor() {
    this.#resolveToken()
  }

  #resolveToken() {
    const onResponse = (event: MessageEvent<{ token?: AuthToken, secret?: string }>) => {
      const { token, secret } = event.data

      if (
        !WEBAPP_ORIGIN_LIST.includes(event.origin) // Match allowed origins
        || secret !== this.#secret // Match session secret
        || !token // Check if token exists
      ) return

      this.promise.resolve(token)

      // Remove listeners
      window.removeEventListener('message', onResponse)
      AuthChannel.removeEventListener('message', onResponse as any)
    }

    window.addEventListener('message', onResponse, { passive: false })
    AuthChannel.addEventListener('message', onResponse as any, { passive: false })
  }

  /** Returns URL for authorization */
  createURL(provider: AuthProvider) {
    const url = new URL(`${WEBAPP_ORIGIN}/auth`)

    url.searchParams.append('provider', provider)
    url.searchParams.append('secret', encodeURIComponent(this.#secret))
    url.searchParams.append('origin', encodeURIComponent(location.origin))

    return url
  }
}
