import { type InferErrorFromMap, createErrorMap, destroyStreams, promiseDebounce } from 'core'
import type { RemovableRef, StorageLikeAsync } from '@vueuse/core'
import { AudioBlob } from 'vocal-recorder'
import idb from 'localforage'

export const log = Object.assign(
  console.debug.bind(null, '%c🤖 RecorderUI:', 'color: bluesky'),
  {
    error: console.error.bind(null, '%c🤖 RecorderUI:', 'color: bluesky')
  }
)

/** Possible error for recorder ui */
export const Errors = createErrorMap({
  // Asset related
  RecorderLoadFailed: 'Failed to load record assets',
  SignatureRenderFailed: 'Failed to render the signature image',

  // Server related
  UIDFetchFailed: 'Failed to get a new uid token from server',
  UIDAlreadyUsed: 'An audio has been already uploaded using this uid',
  NetworkIssue: 'We had an unexpected issue while connecting with the server',

  // Flow related
  InitFailed: 'Failed to initialize the recorder',
  UploadFailed: 'Upload process failed',
  NullRecordingResult: 'Recording produces no result',

  // Microphone releated
  MicPermissionDenied: 'Microphone permission has been denied',
  MicNotFound: 'No microphone devices were found'
})

/** Represents Error type from - {@link Errors} map */
export type RecorderError = InferErrorFromMap<typeof Errors>

export function useAudioDevices(config: {
  onUpdated?: (list: MediaDeviceInfo[]) => void
} = {}) {
  const isSupported = useSupported(() => navigator && navigator.mediaDevices && navigator.mediaDevices.enumerateDevices)
  const devices = ref<MediaDeviceInfo[]>([])

  async function update() {
    if (!isSupported.value)
      return

    const list = await navigator.mediaDevices.enumerateDevices().catch(() => [])
    const inputs = list.filter(i => i.kind === 'audioinput') || []

    devices.value = inputs
    config.onUpdated?.(inputs)
  }

  if (isSupported.value) {
    useEventListener(navigator.mediaDevices, 'devicechange', update)
    update()
  }

  return { isSupported, devices, update }
}

export const useMicChanges = createSharedComposable(() => {
  // Events
  const events = {
    deviceChanged: createEventHook<MediaDeviceInfo[]>(),
    permissionChanged: createEventHook<boolean>()
  }

  /** Whether microphone permission is granted */
  const hasPermission = ref(false)

  const { devices, update: updateDeviceList } = useAudioDevices({
    onUpdated: events.deviceChanged.trigger
  })

  // Functions
  const log = console.debug.bind(null, '%c🔉 Microphone:', 'color: white; background: black;')

  /** Silently checks if microphone permission is granted */
  async function checkPermission() {
    return navigator.mediaDevices.enumerateDevices()
      .then(map => !!map.find(i => i.deviceId !== ''), () => false)
  }

  /** Requests microphone permission */
  async function requestPermission(config: {
    onError?: (error: Error) => void
  } = {}) {
    try {
      destroyStreams(
        await navigator.mediaDevices.getUserMedia({ audio: true, video: false })
      )

      return true
    }
    catch (error) {
      if (error instanceof Error)
        config.onError?.(error)
      return false
    }
  }

  async function probePermissionChanges() {
    let status = {}! as PermissionStatus
    let timer: NodeJS.Timeout

    function updateState(granted: boolean) {
      log('permission changed. Granted:', granted)
      events.permissionChanged.trigger(granted)
      hasPermission.value = granted

      // Force update audio device list
      if (granted)
        updateDeviceList()
    }

    /** Legacy checker for old devices (mainly Safari) */
    const legacyChecker = promiseDebounce(async (interval = 3000) => {
      const granted = await checkPermission()

      // Update state only if different
      if (hasPermission.value !== granted)
        updateState(granted)

      timer = setTimeout(legacyChecker, granted ? interval : 500)
    })

    async function queryChecker() {
      status = await navigator.permissions.query({ name: 'microphone' as any })
      status.onchange = () => updateState(status.state === 'granted')
    }

    try {
      await checkPermission().then(updateState)
      await (device.isSafari ? legacyChecker() : queryChecker())

      log('started listening for permission changes')
    }
    catch (error) {
      log('falling back to using legacy checker due to error', { error })
      await legacyChecker()
    }

    tryOnBeforeUnmount(() => {
      status.onchange = null
      clearInterval(timer)
      log('disposed prober')
    })
  }

  // Start listener
  probePermissionChanges()

  return {
    events,
    devices,
    hasPermission,
    checkPermission,
    requestPermission
  }
})

export const useAudioDropzone = createSharedComposable(() => {
  /** Max size of allowed audio file - 500mb */
  const maxSize = 5e+8

  const audio = ref<AudioBlob>()
  const isProcessing = ref(false)
  const isReady = ref(false)

  // Events
  const { on: onGetAudio, trigger: setAudioFile } = createEventHook<AudioBlob>()

  const { isOverDropZone } = useDropZone(document.body, {
    dataTypes(types) {
      const allowed = [
        'audio/wav',
        'audio/mpeg',
        'audio/m4a'
      ]

      return !!types.find(i => allowed.includes(i))
    },

    onDrop: onFilesDropped
  })

  // TODO: add fix in vocal-recorder upstream
  /** Fix issues with dropped audio file mimetypes */
  function cleanupFile(file: File) {
    switch (file.type) {
      case 'audio/x-m4a':
        return new File([file], file.name, { type: 'audio/mp4' })

      default:
        return file
    }
  }

  async function onFilesDropped(files: File[] | null) {
    if (!files || files.length <= 0)
      return

    const file = cleanupFile(files[0])

    if (file.size > maxSize)
      return alert('Audio file size is larger than 50MB. Large files are not supported.')

    isProcessing.value = true
    audio.value = await AudioBlob.fromFile(file)
    await setAudioFile(audio.value)
    isProcessing.value = false

    track('Dropped audio file', {
      name: file.name,
      duration: audio.value.duration.seconds
    })

    isReady.value = true
  }

  // Opens files window to select an audio file
  async function selectFile() {
    const audio = await chooseFile({
      accept: 'audio/*'
    })

    if (audio)
      await onFilesDropped([audio])
  }

  function clear() {
    delete audio.value
    isProcessing.value = false
    isReady.value = false
  }

  return { maxSize, audio, isOverDropZone, isProcessing, isReady, clear, onGetAudio, selectFile }
})

/** Stores {@link AudioBlob} in IndexedDB storage */
export function usePersistentAudio(key: string, initialValue?: AudioBlob) {
  return useStorageAsync(key, initialValue as any, idb as StorageLikeAsync, {
    shallow: true,

    serializer: {
      read(file: any) {
        if (file instanceof Blob === false)
          throw new Error(`Blob was stored as type: ${typeof file}`)

        return AudioBlob.fromFile(file)
      },

      write: blob => blob
    }
  }) as unknown as RemovableRef<AudioBlob | undefined>
}

export function shouldUseFallbackServer(id: number) {
  const users = [
    2414,
    24265,
    2897,
    24997,
    24612,
    25892,
    21346,
    16852,
    27311,
    28080,
    26546,
    27867,
    11979,
    28010,
    6723,
    19420,
    18377,
    14123,
    20278,
    27747,
    27686,
    27667,
    27674,
    17730,
    19787,
    23525,
    23894,
    13645,
    23752,
    27683,
    25015,
    18677,
    18986,
    27666,
    27949,
    21162,
    24093,
    55,
    25405,
    28109,
    27610,
    14226,
    27170,
    27442,
    24256
  ]

  return users.includes(id)
}
