import { getURLSearchParams } from 'core'
import { Logger, PublicClientApplication } from '@azure/msal-browser'
import { BrowserCrypto } from '@azure/msal-browser/dist/crypto/BrowserCrypto'
import { PkceGenerator } from '@azure/msal-browser/dist/crypto/PkceGenerator'

interface MSAuthURLParams {
  provider?: 'microsoft'
  state?: `${string}|outlook`
  code?: string
}

const env = {
  CLIENT_ID: import.meta.env.VITE_APP_OUTLOOK_CLIENT_ID,
  REDIRECT_URI: `${location.origin}/auth`,
  SCOPES: 'openid email profile user.read',

  PKCE_VERIFIER_KEY: 'ms-pkce-verifier'
}

export class MSAuthProvider {
  static async login() {
    const client = new PublicClientApplication({
      auth: {
        clientId: env.CLIENT_ID
      }
    })

    const pkce = await this.generatePKCE()
    await client.loginRedirect({
      redirectUri: env.REDIRECT_URI,
      scopes: env.SCOPES.split(' '),
      state: 'outlook',
      onRedirectNavigate(href) {
        const url = new URL(href)
        url.searchParams.set('code_challenge', pkce.challenge)
        location.href = url.href

        return false
      }
    })
  }

  static async parseToken() {
    const { state, code } = getURLSearchParams<MSAuthURLParams>()

    if (!state?.includes('outlook'))
      return
    if (!code)
      throw new Error('Redirect hash did not include code')

    const formdata = new URLSearchParams()
    formdata.append('code', code)
    formdata.append('client_id', env.CLIENT_ID)
    formdata.append('code_verifier', this.getPKCEVerifier())
    formdata.append('redirect_uri', env.REDIRECT_URI)
    formdata.append('scope', env.SCOPES)
    formdata.append('grant_type', 'authorization_code')

    const requestOptions: RequestInit = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      body: formdata,
      redirect: 'follow'
    }

    const token = await fetch('https://login.microsoftonline.com/common/oauth2/v2.0/token', requestOptions)
      .then(response => response.json())
      .then(result => result.access_token)

    return token as string
  }

  // Generate PKCE for Login
  static async generatePKCE() {
    const crypto = new BrowserCrypto(new Logger({}))
    const pkce = await new PkceGenerator(crypto).generateCodes()

    // Store verifier for access_token exchange
    sessionStorage.setItem(env.PKCE_VERIFIER_KEY, pkce.verifier)

    return pkce
  }

  static getPKCEVerifier() {
    const verifier = sessionStorage.getItem(env.PKCE_VERIFIER_KEY)

    if (!verifier)
      throw new Error('PKCE verifier was not saved!')
    return verifier
  }
}
